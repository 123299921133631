export const environment = {
  production: true,
  api: 'http://www.hmg-ipess.saude.df.gov.br/rest/api',
  auth: 'http://www.hmg-ipess.saude.df.gov.br/auth/api',
  clientId: 'sigihweb',
  basicAuthorization: 'Basic c2lnaWh3ZWI6c2VjcmV0',
  csvIntervalMin: 5,
  microServiceFishbone: '/fishbone/',
  versaoEcossistema: '3.3',
  versaoAppWeb:'1.7.0'
};
