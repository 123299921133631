import {NgModule} from '@angular/core';
import {HomeComponent} from '../view/home/home.component';
import {SignupComponent} from '../view/home/signup/signup.component';
import {SigninComponent} from '../view/home/signin/signin.component';
import {SharedModule} from './shared.module';
import {Routes, RouterModule} from '@angular/router';
import {SendEmailComponent} from '../view/home/password/send-email/send-email.component';
import {InvalidTokenComponent} from '../view/home/password/invalid-token/invalid-token.component';
import {NewPasswordComponent} from '../view/home/password/new-password/new-password.component';
import {ExportComponent} from '../view/export/export.component';
import {QuillModule} from 'ngx-quill';

const ROUTES: Routes = [
  {
    path: '', component: HomeComponent,
    children: [
      {path: '', redirectTo: 'signup', pathMatch: 'full'},
      {path: 'signin', component: SigninComponent},
      {path: 'signup', component: SignupComponent},
      {path: 'recover-password', component: SendEmailComponent},
      {path: 'new-password/:token', component: NewPasswordComponent},
      {path: 'invalid-token', component: InvalidTokenComponent},
    ]
  }
]

@NgModule({
  declarations: [HomeComponent, SignupComponent, SigninComponent, SendEmailComponent, NewPasswordComponent,
    InvalidTokenComponent],
  imports: [SharedModule, RouterModule.forChild(ROUTES)],
  exports: [HomeComponent]
})
export class HomeModule {

}
